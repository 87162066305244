/*body {*/
/*  margin: 0;*/
/*  padding: 0;*/
/*  font-family: sans-serif;*/
/*}*/

h4 {
    font-family: 'Georgia', serif !important;
    font-size: 18px;
    font-weight: 500;
}

.shortlist-panel-overall{
    margin-top: 20px;
  }
  .shortlist-heading-1{
    margin: 10px 0px;
  }
  
  .shortlist-heading-2{
    margin: 14px;
  }
  
  .shortlist-heading-black{
    font-size: 15px;
    font-weight: 600;
    color: black;
    padding: 8px;
    cursor: pointer;
  }
  
  .shortlist-heading-blue{
    font-size: 15px;
    font-weight: 600;
    color: #03a9f4;
    padding: 8px;
    cursor: pointer;
  }
  .click-shortlist-venue{
  cursor: pointer;
  margin-bottom: 10px;
  }
  
  .display-flex-shortlist{
    display: flex;
    align-items: center;
  }
  
  .mright-1{
    margin-right: 12px;
  }
  .tags{
      width:fit-content!important;
      border: 1px solid #03a9f4; 
      padding: 4px; 
      margin-right: 12px;
      color: white;
      background-color: #03a9f4;
      border-radius: 8px;
      font-weight: 700;
    }
  
  .tags-rec-venues{
      border: 1px solid grey; 
      padding: 6px; 
      margin: 0px 4px 0px 2px;
      color: black;
      border-radius: 8px;
      background-color: #D3D3D3;
    }
    .tags-rec-venues-veg{
      border: 1px solid grey; 
      padding: 6px; 
      margin: 0px 4px 0px 2px;
      color: black;
      border-radius: 8px;
      background-color: #90EE90;
    }
    .tags-rec-venues-non-veg{
      border: 1px solid grey; 
      padding: 6px; 
      margin: 0px 4px 0px 2px;
      color: black;
      border-radius: 8px;
      background-color: #FFCCCB;
    }
    .recommended-box{
      margin: 20px;
    }
    .space-right{
      margin-right: 3px;
    }
  
    .margin-heading{
      margin-left: 14px;
    }
  
    .zero-padding{
      padding: 0px;
    }
  
    .missing-details-padding{
      padding: 14px !important;
    }
  
    .radio-content-styling{
      padding: 2px 0px 0px 4px;
    }
  
    .font-weigth-details{
      font-weight: 600;
    }
    .width-content{
      width: auto;
    }
  
    .switch-template{
      padding: 10px;
      cursor: pointer;
    }
  
    .vm-modal-z-index{
      z-index: 1050;
    }
    
    .vm-navbar-z-index{
      z-index: 500;
    }

    .vm-upload-button{
      margin-right: 10px;
      border-color: #e1e1e1 !important;
    }

    .delete-audio{
      position: absolute;
      left: 281px;
      top: 0px;
      background-color: #EFEFEF;
      height: 54px;
      width: 42px;
      border-radius: 0px;
    }

    .delete-audio-icon{
      position: absolute;
      top: 19px;
      left: 14px
    }
  
